import numeral from 'numeral';

// ----------------------------------------------------------------------

// export function fCurrency(number) {
//   return numeral(number).format(Number.isInteger(number) ? '€0,0' : '€0,0.00');
// }
export function fCurrency(number) {
  const formattedValue = numeral(number).format(Number.isInteger(number) ? '0,0.00' : '0,0.00');
  return `€${formattedValue}`;
}
export function fCurrencyByType(number , type) {
  const formattedValue = numeral(number).format(Number.isInteger(number) ? '0,0.00' : '0,0.00');
  let symbol = type==="EUR"  ? "€" : type==="USD" ? '$' : type==="GBP" ? "£" : "€"
  return `${symbol}${formattedValue}`;
}
export function fNegativeCurrencyByType(number , type) {
  const formattedValue = numeral(number).format(Number.isInteger(number) ? '0,0.00' : '0,0.00');
  let symbol = type==="EUR"  ? "€" : type==="USD" ? '$' : type==="GBP" ? "£" : "€"
  return `${symbol}-${formattedValue}`;
}
export function fCurrencyDecimal(number) {
  const formattedValue = numeral(number).format(Number.isInteger(number) ? '0,0.00' : '0,0.00');
  return `€${formattedValue}`;
}
export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
