// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  TableContainer,
  Stack,
  Skeleton
} from '@mui/material';
import { TableHeadCustom } from 'src/components/table';
import { fNumber } from 'src/utils/formatNumber';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
// ----------------------------------------------------------------------
const { RangePicker } = DatePicker;


export default function WeeklyWordCount({
  loading, title, subheader, tableData, tableLabels, salesFilterDate, hadleFilterDate, ...other
}) {
  console.log("salesFilterDate", salesFilterDate)
 const minDate = dayjs('2023-01-01');
 const disabledDate = current => {
   return current && current < minDate;
 };
  return (
    <>
      <Card {...other} style={{ height: "100%" }}      >
        <CardHeader
          title={title}
          sx={{ mb: 3 }}
        />
        <Stack alignItems='center' justifyContent='center' sx={{ m: 2 }}>
          <RangePicker
            format={'DD/MM/YYYY'}
            style={{ width: '350px', font: 'inherit' }}
            placeholder={['Creation Start Date', 'Creation End Date']}
            className={'antd-date-range'}
            disabledDate={disabledDate}
            variant={'borderless'}
            defaultValue={[dayjs(salesFilterDate[0]?.split('T')[0], 'YYYY-MM-DD'), dayjs(salesFilterDate[1]?.split('T')[0], 'YYYY-MM-DD')]}
            onChange={(e) => {
              const startDate = e ? new Date(e[0]?.$d) : null;
              const endDate = e ? new Date(e[1]?.$d) : null;
              if (startDate) {
                startDate.setHours(0, 0, 0, 0);
              }
              if (endDate) {
                endDate.setHours(23, 59, 59, 999);
              }
              hadleFilterDate([startDate, endDate]);
            }} />

        </Stack>

        {loading ? <Skeleton height={300} /> :

          <TableContainer >
            <Table >
              <TableHeadCustom headLabel={tableLabels}
                 sx={{
                  '& .MuiTableCell-head': {
                      bgcolor: '#3be058',
                      color: '#ffff',
                  },
                  '&.MuiTableHead-root': {
                    padding: 0,
                  },
              }}

              />
              <TableBody>
                <TableRow>
                  {/* <TableCell align='center'>{row?.dateRange}</TableCell> */}
                  <TableCell align='center'>{fNumber(tableData?.eTypeTotal)}</TableCell>
                  {/* <TableCell align='center'>{fNumber(tableData?.etrTypeTotal)}</TableCell> */}
                  <TableCell align='center'>{fNumber(tableData?.othersTotal)}</TableCell>
                </TableRow>
                {/* {tableData?.map((row) => (
                  <MonthlyRate key={row.id} row={row} />
                ))} */}
              </TableBody>
            </Table>
          </TableContainer>}
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------


function MonthlyRate({ row }) {
  return (
    <TableRow>
      {/* <TableCell align='center'>{row?.dateRange}</TableCell> */}
      <TableCell align='center'>{fNumber(row?.EType)}</TableCell>
      <TableCell align='center'>{fNumber(row?.ETRType)}</TableCell>
      {/* <TableCell>{Number(row.convertedTotal).toFixed(2)}%</TableCell> */}
    </TableRow>
  );
}
