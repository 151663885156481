// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  TableContainer,
  FormControl,
  Select,
  MenuItem, Skeleton
} from '@mui/material';
import { sum } from 'lodash';
import { TableHeadCustom } from 'src/components/table';
import { fNumber } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------


export default function MonthlyWordCount({
  loading, title, subheader, tableData, tableLabels, salesFilterYear, hadleFilterYear, ...other
}) {
  console.log("tableData", tableData)

  return (
    <>
      <Card {...other} style={{
        height: "100%"
      }}
      >
        {/* <CardHeader
          title={title}
          sx={{ mb: 3 }}
          action={
            <FormControl variant="standard" sx={{  minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={salesFilterYear}
                name="year"
                onChange={(e) => hadleFilterYear(e)}
                label="Year"
              >
                <MenuItem value={'2022'}>2022</MenuItem>
                <MenuItem value={'2023'}>2023</MenuItem>
                <MenuItem value={'2024'}>2024</MenuItem>
              </Select>
            </FormControl>
          }
        />        */}
         {loading ? <Skeleton height={300} /> :

          <TableContainer>
            <Table >
              <TableHeadCustom headLabel={tableLabels}            
                sx={{
                  '& .MuiTableCell-head': {
                    bgcolor: '#3be058',
                    color: '#ffff',
                },
                '&.MuiTableHead-root': {
                  padding: 0,
                  },
                }}

              />
              <TableBody>
                {tableData?.map((row) => (
                  <MonthlyRate key={row.id} row={row} />
                ))}
                 <TableRow>
      <TableCell align='center' style={{
        fontWeight:'bold'
      }}>Total</TableCell>
      <TableCell align='center' style={{
        fontWeight:'bold'
      }}>{fNumber(sum(tableData?.map(item=>item?.EType)))}</TableCell>
      <TableCell align='center' style={{
        fontWeight:'bold'
      }}>{fNumber(sum(tableData?.map(item=>item?.OtherType)))}</TableCell>
    </TableRow>
              </TableBody>
            </Table>
          </TableContainer>}
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------


function MonthlyRate({ row }) {
  return (
    <TableRow>
      <TableCell align='center'>{row?.month}</TableCell>
      <TableCell align='center'>{fNumber(row?.EType)}</TableCell>
      {/* <TableCell align='center'>{fNumber(row?.ETRType)}</TableCell> */}
      <TableCell align='center'>{row?.OtherType ? fNumber(row?.OtherType):"N/A"}</TableCell>
      {/* <TableCell>{Number(row.convertedTotal).toFixed(2)}%</TableCell> */}
    </TableRow>
  );
}
