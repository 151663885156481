// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  TableContainer,
  Skeleton,
} from '@mui/material';
import { sum } from 'lodash';
import { TableHeadCustom } from 'src/components/table';
import { fNumber, fPercent } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------

export default function ProjectsIssuesCount({
  loading,
  title,
  subheader,
  tableData,
  tableLabels,
  salesFilterYear,
  hadleFilterYear,
  ...other
}) {
  return (
    <>
      <Card
        {...other}
        style={{
          height: '100%',
        }}
      >
        <CardHeader title={title} sx={{ mb: 3 }} />
        {loading ? (
          <Skeleton height={300} />
        ) : (
          <TableContainer>
            <Table>
              <TableHeadCustom
                headLabel={tableLabels}
                sx={{
                  '& .MuiTableCell-head': {
                    bgcolor: '#3be058',
                    color: '#ffff',
                  },
                  '&.MuiTableHead-root': {
                    padding: 0,
                  },
                }}
              />
              <TableBody>
                {tableData?.map((row) => (
                  <MonthlyRate key={row.id} row={row} />
                ))}
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {fNumber(sum(tableData?.map((item) => item?.projects)))}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {fNumber(sum(tableData?.map((item) => item?.issues)))}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {fPercent(sum(tableData?.map((item) => item?.issues)) /sum(tableData?.map((item) => item?.projects))*100) }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

function MonthlyRate({ row }) {
  return (
    <TableRow>
      <TableCell align="center">{row?.month}</TableCell>
      <TableCell align="center">{fNumber(row?.projects)}</TableCell>
      <TableCell align="center">{fNumber(row?.issues)}</TableCell>
      <TableCell align="center">{fPercent((Number(row?.issues) / Number(row?.projects)) * 100)}</TableCell>
    </TableRow>
  );
}
