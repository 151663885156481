// @mui
import { TableRow, TableCell, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function TableSkeleton({ rows , columns , ...other }) {
  return (
    Array.from({ length: rows }).map((_, rowIndex) => (
      <TableRow key={rowIndex} {...other}>
        {Array.from({ length: columns }).map((_, columnIndex) => (
          <TableCell key={columnIndex} align="center">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </TableCell>
        ))}
      </TableRow>
    ))
  );
}
