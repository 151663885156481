import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button, CircularProgress } from '@mui/material';
// routes
import { PATH_AUTH_CLIENT, PATH_AUTH_LINGUIST } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
import Image from '../../../components/Image';
// sections
import { LoginForm } from '../../../sections/auth/clientAuth/login';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CLIENT_EMAIL_VERIFICATION } from 'src/graphQL/queries';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '50%',
  minHeight: '80vh',
  display: 'flex',
  color: '#fff',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: 'linear-gradient(to bottom, rgba(65, 59, 121, 0.93), rgba(15, 50, 147, 0.82)),url(/assets/bg_login.jpg), url(https://minimal-assets-api-dev.vercel.app/assets/images/contact/hero.jpg)',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const [isVerified, setIsVerified] = useState(true)
  const [resendEmail, {data, loading}] = useLazyQuery(CLIENT_EMAIL_VERIFICATION)
  const [email, setEmail] = useState('')
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const handleResend = async() => {
    await resendEmail({variables:{email}})
  }
  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH_CLIENT.register}>
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mb: 5 }}>
            I tuoi progetti, monitorati e tradotti.
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '15px', lineHeight: '23px', px: 5, ml: 2 }}> <ul>
              <li> Comunicazione senza interruzioni </li>
              <li> Gestione sicura dei file</li>
              <li> Flussi di lavoro personalizzati </li>
              <li> Collaborazione semplificata </li>
              <li>
              Monitoraggio completo dei progetti
              </li>
              <li>Assistenza clienti eccezionale </li>
            </ul>
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
         {isVerified ? <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                Accedi a Planet Clients!
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Inserisci i tuoi dati qui sotto.</Typography>
              </Box>

              <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api-dev.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip>
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>admin@admin.com</strong> / password : <strong>12345</strong>
            </Alert> */}

            <LoginForm setEmail={setEmail} setIsVerified={setIsVerified} />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH_CLIENT.register}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle> :  <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1, display:"block" }}>
                        {data && <Alert severity="success">Email inviata!</Alert>}
                <Typography variant="h4" gutterBottom>
                Controlla la tua email!
                </Typography>
                <Typography variant='body1' sx={{ color: 'text.secondary',mt:1 }}>
                Abbiamo inviato un link di verifica a:</Typography>
                <Link href={`mailto:${email}`} underline="always">
      {email}
    </Link><br/>
    <Button variant="contained"onClick={()=>handleResend()} sx={{mt:3}}>
      {loading ? <CircularProgress sx={{color:"white", padding:"5px"}}/> : "Reinvia collegamento"
      }</Button>

              </Box>

              <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`/favicon/favicon.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip>
            </Stack>
            
          </ContentStyle>}
        </Container>
      </RootStyle>
    </Page>
  );
}
