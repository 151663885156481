import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
import LinguistLogin from '../pages/auth/linguistAuth/Login';
import VerifyEmail from 'src/pages/linguist/VerifyEmail';
import ClientLogin from '../pages/auth/clientAuth/Login';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children, userType }) {
  const { isAuthenticated, isInitialized, type, user } = useAuth();
  const userHasRequiredType =  userType.indexOf(type) !== -1 ? true : false
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && userHasRequiredType) {
    if (user?.profileStatus === "unverified" && user?.type === "Linguist") {
      return <VerifyEmail />
    }
    return <>{children}</>;
  }
  else {
    if (userType[0] === 'user') {
      return <Login />
    } else if (userType[0] === 'Client') {
      return <ClientLogin />
    }
    
    else {
      return <LinguistLogin />
    }

  }
  // if (requestedLocation && pathname !== requestedLocation && userHasRequiredType) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }

  // return <>{children}</>;
}
