import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, OutlinedInput, InputAdornment, FormHelperText, Alert, Container, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { VERIFY_USER, SEND_EMAIL_VERIFICATION_CODE } from 'src/graphQL/queries';
import { styled } from '@mui/material/styles';
import Page from 'src/components/Page';
import { FormProvider } from 'src/components/hook-form';
import useAuth from 'src/hooks/useAuth';
import { PATH_LINGUIST } from 'src/routes/paths';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '65vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const VerifyEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user,login } = useAuth();
  const navigate = useNavigate()
  const [verifyUser, { loading }] = useLazyQuery(VERIFY_USER, { fetchPolicy: 'no-cache' });
  const [sentVerificationCode, { loading: sendingVerificationCode }] = useLazyQuery(SEND_EMAIL_VERIFICATION_CODE, { fetchPolicy: 'no-cache' });

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;



  console.log("Errors", errors)
  // const values = watch();
  // console.log(values)
  useEffect(() => {
    const target = document.querySelector('input.field-code');

    target?.addEventListener('paste', handlePaste);

    return () => {
      target?.removeEventListener('paste', handlePaste);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaste = (event) => {
    let data = event.clipboardData.getData('text');

    data = data.split('');

    [].forEach.call(document.querySelectorAll('.field-code'), (node, index) => {
      node.value = data[index];

      const fieldIndex = `code${index + 1}`;

      setValue(fieldIndex, data[index]);
    });

    event.preventDefault();
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;

    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    handleChange(event);
  };
  // .then((res) => {
  //   console.log(res)
  //   login(res.data.linguistUsers)
  // })
  const onSubmit = async (data) => {
    try {
      await verifyUser({
        variables: {
          email: user.email,
          authCode: `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
        }
      }).then((res) => {
        console.log(res.data.verifyUserEmail)
        login(res.data.verifyUserEmail)
        enqueueSnackbar('Email Varified!', { variant: 'success' });
        navigate(PATH_LINGUIST.user.translationProjects, { replace: true });
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Invalid Code', { variant: 'error' });

    }
  };

  const handleSendCode = async () => {
    try {
      await sentVerificationCode({ variables: { email: user.email } })
        .then((res) => {
          enqueueSnackbar('Code Re-Sent!', { variant: 'success' });
        }
        )
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Page title="Verify Email">
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Verify Email Address
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Please enter 6 digit verification code sent to your email address!
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Stack direction="row" spacing={2} justifyContent="center">
                {['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].map((name, index) => (
                  <Controller
                    key={name}
                    name={`code${index + 1}`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <OutlinedInput
                        {...field}
                        error={!!error}
                        autoFocus={index === 0}
                        placeholder="-"
                        onChange={(event) => handleChangeWithNextField(event, field.onChange)}
                        inputProps={{
                          className: 'field-code',
                          maxLength: 1,
                          sx: {
                            p: 0,
                            textAlign: 'center',
                            width: { xs: 36, sm: 56 },
                            height: { xs: 36, sm: 56 },
                          },
                        }}
                      />
                    )}
                  />
                ))}
              </Stack>
              {(!!errors.code1 || !!errors.code2 || !!errors.code3 || !!errors.code4 || !!errors.code5 || !!errors.code6) && (
                <FormHelperText error sx={{ px: 2 }}>
                  Code is required
                </FormHelperText>
              )}
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} sx={{ mt: 3 }}>
                Verify Email
              </LoadingButton>
              <Button variant="text" onClick={handleSendCode}>Re-Send Verification Code</Button>

            </Stack>
          </FormProvider>

        </ContentStyle>
      </Container>
    </Page>
  )
}

export default VerifyEmail