// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_INTRANET = '/intranet';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_LINGUIST = '/vendors';
const ROOTS_CLIENT = '/clients';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_INTRANET,
  login: path(ROOTS_INTRANET, '/'),
  register: path(ROOTS_INTRANET, 'register'),
  loginUnprotected: path(ROOTS_INTRANET, '/login-unprotected'),
  registerUnprotected: path(ROOTS_INTRANET, '/register-unprotected'),
  verify: path(ROOTS_INTRANET, '/verify'),
  resetPassword: path(ROOTS_INTRANET, '/reset-password'),
  newPassword: path(ROOTS_INTRANET, '/new-password'),
  quoteInterpretationClientView: (id) => path(ROOTS_AUTH, `interpretationQuote/${id}/clientView`),
  quoteInterpretationClientViewAM: (id, lang) => path(ROOTS_AUTH, `interpretationQuote/${id}/${lang}/clientView`),
  quoteCongressInternationaliClientView: (id, lang) =>
    path(ROOTS_AUTH, `interpretationQuote/${id}/congressiinternazionali/${lang}/clientView`),
  quoteRafikyClientView: (id, lang) => path(ROOTS_AUTH, `interpretationQuote/${id}/rafiky/${lang}/clientView`),
  quoteTranslationClientView: (id) => path(ROOTS_AUTH, `translationQuote/${id}/clientView`),
  quoteTranslationClientViewAM: (id, lang) => path(ROOTS_AUTH, `translationQuote/${id}/${lang}/clientView`),
  quoteLingoyouClientView: (id, lang) => path(ROOTS_AUTH, `translationQuote/${id}/lingoyou/${lang}/clientView`),
  quoteLingoyouAcademyClientView: (id, lang) =>
    path(ROOTS_AUTH, `translationQuote/${id}/lingoyouacademy/${lang}/clientView`),
  quotePrivacyClientView: (id, lang) =>
    path(ROOTS_AUTH, `translationQuote/${id}/privacyandcybersecurity/${lang}/clientView`),
  translatedFiles: (id, fileType) => path(ROOTS_AUTH, `files/${id}/${fileType}`),
  brochureClientViewAM: (id, lang) => path(ROOTS_AUTH, `brochure/${id}/${lang}/clientView`),
};

export const PATH_AUTH_LINGUIST = {
  root: ROOTS_LINGUIST,
  login: path(ROOTS_LINGUIST, '/'),
  register: path(ROOTS_LINGUIST, '/register'),
  resetPassword: path(ROOTS_LINGUIST, '/reset-password'),
  newPassword: path(ROOTS_LINGUIST, '/new-password'),
};
export const PATH_AUTH_CLIENT = {
  root: ROOTS_CLIENT,
  login: path(ROOTS_CLIENT, '/'),
  // register: path(ROOTS_CLIENT, '/register'),
  resetPassword: path(ROOTS_CLIENT, '/reset-password'),
  newPassword: path(ROOTS_CLIENT, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};
// http://localhost:3000/vendors/pre-booking/670004edc0ae0367c77dbf30
export const PATH_LINGUIST = {
  root: ROOTS_LINGUIST,
  termsAndConditions: path(ROOTS_LINGUIST, '/terms&conditions'),
  travelPolicy: path(ROOTS_LINGUIST, '/travel-policy'),
  privacyPolicy: path(ROOTS_LINGUIST, '/privacypolicy'),
  translationAllocationResponse: (id) => path(ROOTS_LINGUIST, `/translationAllocationResponse/${id}`),
  barzanoAllocationResponse: (id) => path(ROOTS_LINGUIST, `/barzanoAllocationResponse/${id}`),
  interpretationAllocationResponse: (id) => path(ROOTS_LINGUIST, `/interpretationAllocationResponse/${id}`),
  preBookingResponse: (id) => path(ROOTS_LINGUIST, `/pre-booking/${id}`),
  bookingResponse: (id) => path(ROOTS_LINGUIST, `/booking/${id}`),
  translationPRAllocationResponse: (id) => path(ROOTS_LINGUIST, `/translationPRAllocationResponse/${id}`),
  user: {
    profile: path(ROOTS_LINGUIST, '/profile'),
    editProfile: path(ROOTS_LINGUIST, '/edit-profile'),
    translation: path(ROOTS_LINGUIST, '/services/translation'),
    interpretation: path(ROOTS_LINGUIST, '/services/interpretation'),
    translationProjects: path(ROOTS_LINGUIST, '/translationProjects/list'),
    interpretationProjects: path(ROOTS_LINGUIST, '/interpretationProjects/list'),
    prMenu: path(ROOTS_LINGUIST, '/proof-reading'),
    verifyEmail: path(ROOTS_LINGUIST, '/verify-email'),
    translationProjectView: (allocationId) => path(ROOTS_LINGUIST, `/translationProjects/${allocationId}`),
    interpretationProjectView: (allocationId) => path(ROOTS_LINGUIST, `/interpretationProjects/${allocationId}`),
    prView: (allocationId) => path(ROOTS_LINGUIST, `/proof-reading/${allocationId}`),
    invoice: path(ROOTS_LINGUIST, '/invoice'),
    view: (id) => path(ROOTS_LINGUIST, `/invoice/${id}`),
    invoiceNew: path(ROOTS_LINGUIST, '/invoice/new'),
  },
  marketeer: {
    marketingChannelList: path(ROOTS_LINGUIST, '/marketingChannelList'),
  },
  formatter: {
    formattingFileDetails: (id) => path(ROOTS_LINGUIST, `/formattingProject/${id}`),
    formattingFilesList: path(ROOTS_LINGUIST, '/formattingProjectslist'),
    projectsCalender: path(ROOTS_LINGUIST, '/projectsCalender'),
  },
  technician: {
    technicianSheet: (id) => path(ROOTS_LINGUIST, `/technicianSheets/${id}`),
    technicianSheetsList: path(ROOTS_LINGUIST, '/technicianSheets'),
    invoice: path(ROOTS_LINGUIST, '/invoices'),
    view: (id) => path(ROOTS_LINGUIST, `/invoices/${id}`),
    invoiceNew: path(ROOTS_LINGUIST, '/invoices/new'),
    inventory: path(ROOTS_LINGUIST, '/inventory'),
    inventoryView: (id) => path(ROOTS_LINGUIST, `/inventory/${id}`),
    inventoryCreate: path(ROOTS_LINGUIST, `/inventory/new`),
    inventoryEdit: (id) => path(ROOTS_LINGUIST, `/inventory/${id}/edit`),
  },
};
export const PATH_CLIENT = {
  root: ROOTS_CLIENT,
  client: {
    dashboard: path(ROOTS_CLIENT, '/dashboard'),
    // barzanoCalendar: path(ROOTS_CLIENT, '/barzanoCalendar'),
    editProfile: path(ROOTS_CLIENT, '/edit-profile'),
    instantQuote: path(ROOTS_CLIENT, '/instant-quote'),
    orders: path(ROOTS_CLIENT, '/order'),
    orderView: (id, type) => path(ROOTS_CLIENT, `/order/${type}/${id}`),
  },
  book: {
    interpreter: path(ROOTS_CLIENT, `/book/interpreter`),
    webConference: path(ROOTS_CLIENT, `/book/web-conference`),
    translator: path(ROOTS_CLIENT, `/book/translator`),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  projectsCalendar: path(ROOTS_DASHBOARD, '/projects/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/sticaz/edit`),
    report: (id) => path(ROOTS_DASHBOARD, `/user/report/${id}`),
    quoteReport: (id) => path(ROOTS_DASHBOARD, `/user/report/quote/${id}`),
    clientsReport: (id) => path(ROOTS_DASHBOARD, `/user/report/clients/${id}`),
    callsReport: (id) => path(ROOTS_DASHBOARD, `/user/report/calls/${id}`),
  },
  task: {
    list: path(ROOTS_DASHBOARD, '/task/list'),
    deptList: (dept) => path(ROOTS_DASHBOARD, `/task/list/${dept}`),
    new: path(ROOTS_DASHBOARD, '/task/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/task/${id}/edit`),
  },
  vendor: {
    translator: path(ROOTS_DASHBOARD, '/vendor/translator'),
    interpreter: path(ROOTS_DASHBOARD, '/vendor/interpreter'),
    incomplete: path(ROOTS_DASHBOARD, '/vendor/incomplete'),
    blacklisted: path(ROOTS_DASHBOARD, '/vendor/blacklist-vendors'),
    technician: path(ROOTS_DASHBOARD, '/vendor/technician'),
    view: (id) => path(ROOTS_DASHBOARD, `/vendor/${id}`),
    viewCV: (id) => path(ROOTS_DASHBOARD, `/vendor/${id}/cv`),
    technicianView: (id) => path(ROOTS_DASHBOARD, `/vendor/technician/${id}`),
  },
  barzanoProject: {
    list: path(ROOTS_DASHBOARD, '/barzanoProject/list'),
    deliveryList: path(ROOTS_DASHBOARD, '/barzanoProject/deliverylist'),
    new: path(ROOTS_DASHBOARD, '/barzanoProject/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/barzanoProject/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/barzanoProject/${id}`),
    booking: (id) => path(ROOTS_DASHBOARD, `/barzanoProject/${id}/booking`),
    ocr: path(ROOTS_DASHBOARD, '/barzanoProject/ocr'),
  },
  reports: {
    quotesConversionReports: path(ROOTS_DASHBOARD, '/reports/quote'),
    callRecords: path(ROOTS_DASHBOARD, '/reports/calls'),
    issuesReports: path(ROOTS_DASHBOARD, '/reports/issue'),
    allGeneralProjectsList: path(ROOTS_DASHBOARD, '/reports/allProjectsList'),
    barzanoReports: path(ROOTS_DASHBOARD, '/reports/barzano'),
    totalsalesReport: path(ROOTS_DASHBOARD, '/reports/revenueAnalysis'),
    marketingChannelList: path(ROOTS_DASHBOARD, '/reports/marketingChannelList'),
    expenseReport: path(ROOTS_DASHBOARD, '/reports/expenseReport'),
  },
  management: path(ROOTS_DASHBOARD, '/management'),
  translationProject: {
    list: path(ROOTS_DASHBOARD, '/translationProject/list'),
    new: path(ROOTS_DASHBOARD, '/translationProject/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/translationProject/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/translationProject/${id}`),
    booking: (id) => path(ROOTS_DASHBOARD, `/translationProject/${id}/booking`),
  },
  translationQuote: {
    list: path(ROOTS_DASHBOARD, '/translationQuote/list'),
    new: path(ROOTS_DASHBOARD, '/translationQuote/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/translationQuote/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/translationQuote/${id}`),
    quoteTranslationClientView: (id) => path(ROOTS_DASHBOARD, `/translationQuote/${id}/clientView`),
  },
  quotesConversionReports: {
    root: path(ROOTS_DASHBOARD, '/quoteconversion/reports'),
  },
  brochure: {
    list: path(ROOTS_DASHBOARD, '/brochure/list'),
    new: path(ROOTS_DASHBOARD, '/brochure/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/brochure/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/brochure/${id}`),
    brochureClientView: (id) => path(ROOTS_DASHBOARD, `/brochure/${id}/clientView`),
  },
  expense: {
    list: path(ROOTS_DASHBOARD, '/expense/list'),
    new: path(ROOTS_DASHBOARD, '/expense/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/expense/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/expense/${id}`),
  },
  creditNote: {
    new: path(ROOTS_DASHBOARD, '/credit-note/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/credit-note/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/credit-note/${id}`),
  },
  Calls: {
    call: path(ROOTS_DASHBOARD, '/TwilioCalls/call'),
    record: path(ROOTS_DASHBOARD, '/TwilioCallRecords/list'),
  },
  textToSpeech: {
    text: path(ROOTS_DASHBOARD, '/TextToSpeech/create'),
  },
  company: {
    list: path(ROOTS_DASHBOARD, '/company/list'),
    new: path(ROOTS_DASHBOARD, '/company/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/company/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/company/${id}`),
  },
  interpretationProject: {
    list: path(ROOTS_DASHBOARD, '/interpretationProject/list'),
    new: path(ROOTS_DASHBOARD, '/interpretationProject/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/interpretationProject/${id}/edit`),
    booking: (id) => path(ROOTS_DASHBOARD, `/interpretationProject/${id}/booking`),
    view: (id) => path(ROOTS_DASHBOARD, `/interpretationProject/${id}`),
  },
  interpretationQuote: {
    list: path(ROOTS_DASHBOARD, '/interpretationQuote/list'),
    reports: path(ROOTS_DASHBOARD, '/interpretationQuote/reports'),
    new: path(ROOTS_DASHBOARD, '/interpretationQuote/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/interpretationQuote/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/interpretationQuote/${id}`),
  },
  videoConferenceProject: {
    list: path(ROOTS_DASHBOARD, '/videoConferenceProject/list'),
    new: path(ROOTS_DASHBOARD, '/videoConferenceProject/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/videoConferenceProject/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/videoConferenceProject/${id}`),
  },
  linguistAllocation: {
    new: path(ROOTS_DASHBOARD, '/translationLinguistAllocation/new'),
  },
  vault: path(ROOTS_DASHBOARD, '/vault'),
  vaultFolder: (id) => path(ROOTS_DASHBOARD, `/vault/${id}`),
  vaultFiles: (id, fileType) => path(ROOTS_DASHBOARD, `/vault/${id}/${fileType}`),
  issue: {
    list: path(ROOTS_DASHBOARD, '/issue/list'),
    new: path(ROOTS_DASHBOARD, '/issue/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/issue/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/issue/${id}`),
    reports: path(ROOTS_DASHBOARD, `/issue/reports`),
  },
  inventory: {
    list: path(ROOTS_DASHBOARD, '/inventory/list'),
    new: path(ROOTS_DASHBOARD, '/inventory/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/inventory/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/inventory/${id}`),
    overview: path(ROOTS_DASHBOARD, `/inventory/overview`),
  },
  ticket: {
    list: path(ROOTS_DASHBOARD, '/ticket/list'),
    support: path(ROOTS_DASHBOARD, '/ticket/support'),
    new: path(ROOTS_DASHBOARD, '/ticket/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/ticket/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/ticket/${id}`),
    kanban: path(ROOTS_DASHBOARD, `/ticket/kanban`),
  },
  invoice: {
    createClientInvoice: path(ROOTS_DASHBOARD, '/invoice/barzanoClient/create'),
    editClientInvoice: (id) => path(ROOTS_DASHBOARD, `/invoice/client/${id}/edit`),
    createOtherClientInvoice: path(ROOTS_DASHBOARD, '/invoice/create'),
    editOtherClientInvoice: (id) => path(ROOTS_DASHBOARD, `/invoice/client/${id}/edit`),
    viewClientInvoice: (id) => path(ROOTS_DASHBOARD, `/invoice/client/${id}`),
    listClientInvoice: path(ROOTS_DASHBOARD, '/invoice/client/list'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    createLYCIClientInvoice: path(ROOTS_DASHBOARD, '/invoice/client/create'),
    // edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
