import { createSlice, current } from '@reduxjs/toolkit'



export const lang = createSlice({
  name: 'lang',
  initialState: {
   lang:localStorage.getItem("lang") || 'it'
  },
  reducers: {
   
    changeLang: (state, action)=>{
      state.lang = action?.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeLang } = lang.actions

export default lang.reducer