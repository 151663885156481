// @mui
import { Card, CardHeader, Skeleton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------
function transformData(data) {
  const months = data?.map(item=>item?.month);
  const categories = [];
  const issuesData = new Array(data?.length).fill(0);

  data?.forEach(entry => {
    const monthIndex = months.indexOf(entry.month);
    if (monthIndex !== -1) {
      categories.push(entry.month);
      let percent = Number(Number((Number(entry?.issues)/ Number(entry?.projects))*100).toFixed(2))
      issuesData[monthIndex] = percent;
    } 
  });

  return {
    categories: months,
    series: {
      data: [
        { name: 'Quality Cases', data: issuesData },
      ],
    },
  };
}

export default function ProjectsIssuesChart({ loading, title, data, ...other }) {
  const theme = useTheme();
  const transformedData = data ? transformData(data) : [];
  const chart = {
    categories: transformedData?.categories,
    series:transformedData?.series,
  }
  const { colors, categories, series, options } = chart;

  const chartOptions = useChart({
    colors:[ '#ad34e3' ],
    xaxis: {
      categories,
    },
    tooltip: {
      y: {
        formatter: (value) => {
          return `${value} %`;
        }
      }
    },
    ...options,
  });
  return (
    <>
      <Card {...other} style={{ height: "100%" }}>
        <CardHeader
          title={title}
          sx={{ mb: 3 }}
        />
        {(loading && !data) ? <Skeleton height={300} /> :
          <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
            <Chart type="line" series={series?.data} options={chartOptions} height={364} />
          </Box>}
      </Card>
    </>
  );
}

