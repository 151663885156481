import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export default function UpdatePreBookingRefuseStatus({ handleCloseModal, onRefuse, loading }) {
  const { enqueueSnackbar } = useSnackbar();
  const [reason, setReason] = useState('');

  console.log({ reason });
  const handleStatus = async () => {
    try {
      await onRefuse(reason).then((res) => {
        handleCloseModal();
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };
  return (
    <Stack pt={2} justifyContent="center">
      <Typography variant="subtitle1" style={{ paddingBottom: 16 }}>
        Please share the reason for rejecting this opportunity 
      </Typography>
      <TextField
        multiline
        fullWidth
        label="Reason"
        value={reason ? reason : ''}
        onChange={(e) => setReason(e.target.value)}
        minRows={2}
      ></TextField>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        mt={4}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingButton variant="contained" color="primary" loading={loading} onClick={handleStatus}>
          Confirm{' '}
        </LoadingButton>
        <Button variant="outlined" color="error" onClick={handleCloseModal}>
          Close
        </Button>
      </Stack>
    </Stack>
  );
}
