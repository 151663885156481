import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { CustomSmallSelect } from 'src/components/custom-input';
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

LineChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function LineChart({ title, subheader, chart, ...other }) {
  const { colors, categories, series, options } = chart;
  console.log("chart", chart);
  const [seriesData, setSeriesData] = useState('2019');

  const chartOptions = useChart({
    colors,
    xaxis: {
      categories,
    },
    ...options,
  });

  return (
    <Card {...other} sx={{
      p: 2
    }}>
      <CardHeader title={title} />
      {series.map((item) => (
        <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
          <Chart type="line" series={item.data} options={chartOptions} height={364} />
        </Box>
      ))}
    </Card>
  );
}