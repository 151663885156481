import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, TextField, Autocomplete, InputAdornment } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch, useSelector } from 'react-redux';
import { changeLang } from 'src/redux/slices/lang';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------
const langs = [
  { code: 'IT', label: 'it', phone: '39' },
  { code: 'US', label: 'en', phone: '1' },
];
export default function AccountPopover() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const { lang } = useSelector((state) => state.lang);
  const { user, logout, type } = useAuth();
  // console.log(type)
  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleLinguistLogout = async () => {
    try {
      await logout();
      navigate('/vendors');

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  const handleClientLogout = async () => {
    try {
      await logout();
      navigate('/clients');

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {(() => {
          switch (type) {
            case 'user':
              return (
                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                  Logout
                </MenuItem>
              );
            case 'Linguist':
              return (
                <MenuItem onClick={handleLinguistLogout} sx={{ m: 1 }}>
                  Logout
                </MenuItem>
              );
            case 'Technician':
              return (
                <MenuItem onClick={handleLinguistLogout} sx={{ m: 1 }}>
                  Logout
                </MenuItem>
              );
            case 'Formatter':
              return (
                <MenuItem onClick={handleLinguistLogout} sx={{ m: 1 }}>
                  Logout
                </MenuItem>
              );
            case 'Marketeer':
              return (
                <MenuItem onClick={handleLinguistLogout} sx={{ m: 1 }}>
                  Logout
                </MenuItem>
              );
            case 'Client':
              return (
                <>
                  <MenuItem onClick={handleClientLogout} sx={{ m: 1 }}>
                    Logout
                  </MenuItem>
                  {/* <div style={{justifyContent:"center"}}>
                  <Autocomplete
      sx={{ width: "120px", justifyContent:"center", margin:"15px" }}
      options={langs}
      autoHighlight
      size='small'
      value={langs.find((lg) => lg.label === lang)}
      onChange={(event, newValue) => {
        if (newValue?.label === 'en') {
          dispatch(changeLang('en'));
        } else {
          dispatch(changeLang('it'));
        }
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          size="small"
          {...params}
          label="Locale"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {params.inputProps.value && (
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${langs.find((lg) => lg.label === params.inputProps.value)?.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${langs.find((lg) => lg.label === params.inputProps.value)?.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                )}
              </InputAdornment>
            ),
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
                  </div> */}
                 
                </>
              );
          }
        })()}
      </MenuPopover>
    </>
  );
}
