import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { CircularProgress, IconButton, LinearProgress,  Stack, TextField, Typography } from '@mui/material';
// utils
import { fData, fNumber } from '../../../utils/formatNumber';
//
import Iconify from '../../Iconify';
import { varFade } from '../../animate';
import FileThumbnail, { fileData } from '../../file-thumbnail';

// ----------------------------------------------------------------------

QuoteMultiFilePreview.propTypes = {
  sx: PropTypes.object,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  thumbnail: PropTypes.bool,
};

export default function QuoteMultiFilePreview({ thumbnail, files, onRemove,editWords,setDocumentWords ,documentWords,sx }) {
  if (!files?.length) {
    return null;
  }

  return (
    <AnimatePresence initial={false}>
      {files.map((file) => {
        const { key, name = '', size = 0 } = fileData(file);
        console.log("files" , file)
        const isNotFormatFile = typeof file === 'string';
        return (
          <Stack
            key={key}
            component={m.div}
            {...varFade().inUp}
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              my: 1,
              px: 1,
              py: 0.75,
              borderRadius: 0.75,
              border: (theme) => `solid 1px ${theme.palette.divider}`,
              ...sx,
            }}
          >
            <FileThumbnail file={file} />

            <Stack flexGrow={1} sx={{ minWidth: 0 }}>
              <Typography variant="subtitle2" noWrap sx={{ width:280 }}>
                {isNotFormatFile ? file : name}
              </Typography>

              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {isNotFormatFile ? '' : fData(size)}
              </Typography>
            </Stack>
            <Stack >
          
            </Stack>
            {onRemove && (
              <Stack> 
                    {
                file?.loading ? <CircularProgress color="success" />                :
                  <div style={{
                    display:'flex' ,
                    flexDirection:"row",
                    alignItems:"center"
                  }}>
                    {
                      (file?.wordData ||!file.word)  ?     <TextField 
                      size="small"
                      label='Words'
                      style={{width:70}}
                      value={file?.word } 
                      name='documentWords' 
                      onChange={(e )=>setDocumentWords(e, name)} />
   :
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      Words:   { fNumber(file.word) }
                    </Typography>
                    }
                   
                  <IconButton edge="end" size="small" style={{
                    paddingLeft:"14px"
                  }} onClick={() => onRemove(file)}>
                <Iconify icon="eva:trash-fill" />
              </IconButton>
                  </div>
              }
                
              </Stack>
             
            )}
          </Stack>
        );
      })}
    </AnimatePresence>
  );
}
